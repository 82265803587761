import { HelmetDatoCms } from 'gatsby-source-datocms';
import React from 'react';

import { getAbsoluteURL } from '../../../common/link';
import { useDirection, useLocale } from '../../../context/locale';

export const SEO = ({
  page,
  tags,
  favicon,
  schema,
  isArticle,
  versions,
  siteURL,
}: any) => {
  const locale = useLocale();
  const direction = useDirection();

  const seoTags = (tags ? tags.tags : []).map((t) => {
    if (
      !isArticle &&
      t.tagName === 'meta' &&
      t.attributes.property === 'og:type'
    ) {
      /* eslint no-param-reassign: "off" */
      t.attributes.content = 'website';
    }
    if (t.tagName === 'title') {
      return {
        ...t,
        content: `${t.content} | Apollo`,
      };
    }

    return t;
  });

  const pageURL = page
    ? getAbsoluteURL({
        url: siteURL,
        pageType: page.model.apiKey,
        locale: page.locale,
        slug: page.slug,
      })
    : null;

  return (
    <HelmetDatoCms
      favicon={favicon}
      seo={{ tags: seoTags }}
      htmlAttributes={{ lang: locale, dir: direction }}
    >
      {versions &&
        versions.map((version, index) => (
          <link
            rel="alternate"
            href={getAbsoluteURL({
              url: siteURL,
              pageType: version.model.apiKey,
              locale: version.locale,
              slug: version.slug,
            })}
            hrefLang={index === 0 ? 'x-default' : version.locale}
            key={version.locale}
          />
        ))}

      <meta
        name="viewport"
        content="height=device-height, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no, target-densitydpi=device-dpi"
      />

      {pageURL && <link rel="canonical" href={pageURL} />}
      {pageURL && <meta property="og:url" content={pageURL} />}

      {schema && (
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      )}
    </HelmetDatoCms>
  );
};
